import React from "react";

// Antd
import { Col, Card } from "antd";

// Icons
import { ExpandIcon } from "@Vytelle/authentication/src/assets/icons";

function Layout({
  logo,
  text,
  onClick
}) {

  return (
    <Col 
      xs={24} 
      sm={12} 
      className="platforms-item" 
      onClick={onClick}
    >
      <Card>
        <img
          src={logo}
          alt="logo"
        />
        <p>{ text } <ExpandIcon /></p>
      </Card>
    </Col>
  );
}

export default Layout;