// Service
import service from "@Vytelle/authentication/src/services/Api/Service";
import StorageService from "@Vytelle/authentication/src/services/StorageService";

// Constants
import { USER_ACTIONS } from "@Vytelle/authentication/src/constants/ActionKeys";
import { API_URLS_COMMON } from "@Vytelle/authentication/src/constants/ApiUrlCommon";
import { APP_ROLE } from "@Vytelle/authentication/src/constants/Permissions";

// General
import { handleAction } from ".";

function login(loginModel) {
  return async (dispatch) => {
    try {
      dispatch(handleAction(USER_ACTIONS.LOGIN_REQUEST));

      // API Calling
      const response = await service.postService(
        API_URLS_COMMON.LOGIN,
        loginModel,
        {}
      );

      dispatch(handleAction(USER_ACTIONS.LOGIN_SUCCESS, response));

      return response;
    } catch (error) {
      dispatch(handleAction(USER_ACTIONS.LOGIN_FAILURE));
      throw error;
    }
  };
}

function validateToken(token) {
  return async (dispatch) => {
    try {
      dispatch(handleAction(USER_ACTIONS.LOGIN_REQUEST));

      // API Calling
      const response = await service.getService(API_URLS_COMMON.TOKEN_INFO, {
        headers: {
          Authorization: token ? `Bearer ${token || null}` : "",
        },
      });

      const result = response?.result || {};

      // Save Access Token
      StorageService.instance.setAccessToken(token);

      // Save User Info
      StorageService.instance.setUserInfo(result);

      dispatch(handleAction(USER_ACTIONS.LOGIN_SUCCESS, response));

      return response;
    } catch (error) {
      dispatch(handleAction(USER_ACTIONS.LOGIN_FAILURE));
      throw error;
    }
  };
}

function logout() {
  return async (dispatch) => {
    try {
      dispatch(handleAction(USER_ACTIONS.LOGOUT_REQUEST));
      await StorageService.instance.deleteLoginData();
      dispatch(handleAction(USER_ACTIONS.LOGOUT_SUCCESS));
    } catch (error) {
      dispatch(handleAction(USER_ACTIONS.LOGOUT_FAILURE, error));
      throw error;
    }
  };
}

function userPermissions() {
  return async (dispatch) => {
    const permission = StorageService.instance.getAppPermissions();

    // If permissions exists, then stopping making API call
    if (permission && Object.keys(permission).length === 0) return;

    try {
      const { accountType } = StorageService.instance.getUserInfo() || {};
      // If account type doesn't exists
      if (!accountType) return;
      // If account type is not admin. We need to check app permission
      if (accountType !== APP_ROLE.ADMIN) {
        dispatch(handleAction(USER_ACTIONS.PERMISSIONS_REQUEST));

        // API Calling
        const response = await service.getService(
          API_URLS_COMMON.GET_APP_PERMISSIONS
        );
        const result = response?.result || {};

        // Save Permissions Info
        StorageService.instance.setAppPermissions(result);
        dispatch(handleAction(USER_ACTIONS.PERMISSIONS_SUCCESS));
      }
    } catch (error) {
      dispatch(handleAction(USER_ACTIONS.PERMISSIONS_FAILURE));
      throw error;
    }
  };
}

// preference
function userPreference() {
  return async (dispatch) => {
    try {
      dispatch(handleAction(USER_ACTIONS.PREFERENCE_REQUEST));
      // API Calling
      const response = await service.getService(API_URLS_COMMON.GET_PREFERENCE);
      const result = response?.result || {};

      // Save preference Info
      StorageService.instance.setAppPreference(result);
      dispatch(handleAction(USER_ACTIONS.PREFERENCE_SUCCESS));
    } catch (error) {
      dispatch(handleAction(USER_ACTIONS.PREFERENCE_FAILURE));
      throw error;
    }
  };
}


export { login, validateToken, logout, userPermissions, userPreference };
