import React from "react";

// Antd
import { Row, Col, Button } from "antd";

// Images
import noPlatform from '@Vytelle/authentication/src/assets/images/no-platform.png';

// Localization
import LOCALIZATION from "@Vytelle/authentication/src/services/LocalizationService";

// Style
import "./style.scss";

function Layout() {

  return (
    <Row gutter={[0, 24]} className="home-page-row">
      <Col className="mx-auto home-page-col">
        <img src={noPlatform} alt="no platform" />
        <p className="mb-1">{ LOCALIZATION.NO_PERMISSION_MESSAGE }</p>
        <p className="mt-0">{ LOCALIZATION.CONTACT_VYTELLE_SUPPORT }</p>
        <a href="mailto:info@vytelle.com">{ }
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="mt-4 mx-auto auth-action"
          >
            {LOCALIZATION.CONTACT_CUSTOMER_SUPPORT}
          </Button>
        </a>
      </Col>
    </Row>
  );
}

export default Layout;