/*
  General Response Handler
*/

import StorageService from "@Vytelle/authentication/src/services/StorageService";

// Error constants
import CODES from "@Vytelle/authentication/src/constants/StatusCodes";

export function handleResponse(response) {
  if (
    response &&
    (response?.status === CODES.SUCCESS ||
      response?.status === CODES.NO_CONTENT ||
      response?.status === CODES.RESOURCE_CREATED)
  ) {
    // in case of PUT/DELETE, Api will respond 204
    return response?.data;
  } else {
    handleError(response);
  }
}

export function handleError(error) {
  if (error?.response?.status === 401) {
    StorageService.instance.deleteLoginData(); // clear session
  }
  throw error;
}
