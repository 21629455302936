/*
    Notifications Helper
*/

import React from "react";

// Antd
import { notification, Modal } from "antd";

// Localization
import LOCALIZATION from "@Vytelle/authentication/src/services/LocalizationService";

// Constants
import { NOTIFICATION_DURATION } from "@Vytelle/authentication/src/constants/General";
import { CrossMarkIcon } from "@Vytelle/authentication/src/assets/icons";

import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

// Style
import "./style.scss";

export function showWarning(message, callNextScreen) {
  Modal.warning({
    title: LOCALIZATION.WARNING,
    content: (
      <div>
        {message?.warnings?.map((val, index) => {
          return <p key={index}>{val}</p>;
        })}
      </div>
    ),
    onOk() {
      callNextScreen(message);
    },
  });
}

// Success
export function successNotification(message, duration = NOTIFICATION_DURATION) {
  const key = "success";
  notification.open({
    closeIcon: <CrossMarkIcon />,
    key,
    duration,
    icon: <CheckCircleOutlined />,
    description:
      typeof message === "string" ? (
        message || "Notification"
      ) : (
        <div>
          {message?.map((val, index) => {
            return <p key={index}>{val}</p>;
          })}
        </div>
      ),
    className: "notification-success",
  });
}

// Error
export function errorNotification(message, duration = NOTIFICATION_DURATION) {
  const key = "error";
  notification.open({
    closeIcon: <CrossMarkIcon />,
    key,
    duration,
    icon: <CloseCircleOutlined />,
    description:
      typeof message === "string" ? (
        message || "Notification"
      ) : (
        <div>
          {message?.map((val, index) => {
            return <p key={index}>{val}</p>;
          })}
        </div>
      ),
    className: "notification-error",
  });
}

// Warning
export function warningNotification(message, duration = NOTIFICATION_DURATION) {
  const key = "warning";
  notification.open({
    closeIcon: <CrossMarkIcon />,
    key,
    duration,
    icon: <WarningOutlined />,
    description: message || "Notification",
    className: "notification-warning",
  });
}

// Info
export function infoNotification(message, duration = NOTIFICATION_DURATION) {
  const key = "info";
  notification.open({
    closeIcon: <CrossMarkIcon />,
    key,
    duration,
    icon: <InfoCircleOutlined />,
    description: message || "Notification",
    className: "notification-info",
  });
}
