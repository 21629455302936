/*
  Application Forgot Password
*/

import React from "react";

// React Dom
import { useHistory } from "react-router-dom";

// Antd
import { Button } from "antd";

// Constants
import APP_URL from '@Vytelle/authentication/src/constants/ApplicationUrls';

// Components
import AuthenticationBase from "..";

// Localization
import LOCALIZATION from "@Vytelle/authentication/src/services/LocalizationService";

function Login() {

  const history = useHistory();

  const onClick = () => {
    history.push(APP_URL.LOGIN);
  }

  return (
    <AuthenticationBase isLoginScreen={true}>
      <h2>{ LOCALIZATION.HELP_IS_ON_THE_WAY }</h2>
      <div>{ LOCALIZATION.HELP_IS_ON_THE_WAY_MESSAGE }</div>
      <Button
        block
        size="medium"
        type="primary"
        className="mt-4 auth-action"
        onClick={onClick}
      >
        { LOCALIZATION.BACK_TO_LOGIN }
      </Button>
    </AuthenticationBase>
  );
}

export default Login;
