/*
  Application Login Page Base Layout
*/

import React, { useEffect } from 'react';

// React Dom
import { Link } from "react-router-dom";

// Redux
import {
  useDispatch,
} from 'react-redux';

// Antd
import { Col, Row } from "antd";

//Components
import Loading from '@Vytelle/authentication/src/components/Loading';

// Actions
import { logout } from '@Vytelle/authentication/src/store/actions/AuthAction';

// Constants
import APP_URL from '@Vytelle/authentication/src/constants/ApplicationUrls';

// Localization
import LOCALIZATION from "@Vytelle/authentication/src/services/LocalizationService";

// Images
import vytelleLogo from '@Vytelle/authentication/src/assets/images/vytelle-logo.svg';

// Style & Icons
import './style.scss';
import { LongArrowIcon } from '@Vytelle/authentication/src/assets/icons';

function AuthenticationBase({
  loading,
  children,
  isLoginScreen
}) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout())
  }, [dispatch]);

  return (
    <>
      {
        loading &&
        // true &&
        <Loading />
      }

      <Row className="authentication-wrapper">
        <Col xs={0} md={12} xl={16} className="image-col">
          <div className="content">
            <h2>{ LOCALIZATION.LOGIN_PAGE_TAGLINE }</h2>
            <a
              href={"https://vytelle.com/?utm_source=vytellesenseui&utm_medium=website&utm_campaign=alwaysprogressing&utm_term=ui"}
              target="_blank"
              rel="noopener noreferrer"
              className="btn">
              <span>{ LOCALIZATION.START_NOW }</span>
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} xl={8} className="form-col">
          <img
            className="login-logo"
            src={vytelleLogo}
            alt="logo"
          />
          <div className='shadow-card'>
            {children}
          </div>
          {
            !isLoginScreen &&
            <div className="back-link">
              <Link to={APP_URL.LOGIN} >
                <LongArrowIcon />{LOCALIZATION.BACK_TO_LOGIN}?
              </Link>
            </div>
          }
          <div className="footer-links">
            <span>{ LOCALIZATION.NEED_HELP }?</span>
            <a href="mailto:info@vytelle.com">{ LOCALIZATION.CONTACT_CUSTOMER_SUPPORT }</a>
          </div>
        </Col>
      </Row>

    </>
  );
}

export default AuthenticationBase;