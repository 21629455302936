/*
  Application Login Page
*/

import React from "react";
import { Link, useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Antd
import { Form, Button } from "antd";

// Components
import AuthenticationBase from "..";
import {
  Email,
  Password,
} from "@Vytelle/authentication/src/components/Common/FormElements";

// Constants
import APP_URL from "@Vytelle/authentication/src/constants/ApplicationUrls";

// Helper
import { getErrorMessage } from "@Vytelle/authentication/src/helpers/GeneralHelper";
import {
  errorNotification,
  successNotification,
} from "@Vytelle/authentication/src/helpers/Notification";

// Actions
import {
  login
} from "@Vytelle/authentication/src/store/actions/AuthAction";

// Localization
import LOCALIZATION from "@Vytelle/authentication/src/services/LocalizationService";

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  // Refs
  const [form] = Form.useForm();
  let formRef = React.createRef();

  // const searchParams = new URLSearchParams(location.search);
  // const token = searchParams.get("token");

  // Redux States
  const loading = useSelector((state) => state?.Auth?.loading);

  const onSubmit = (data) => {
    dispatch(login(data)).then(
      (e) => {
        successNotification(LOCALIZATION.SUCCESSFULLY_LOGIN_MESSAGE);
        const data = e?.data?.result;
        history.push({
          pathname: APP_URL.APP_LANDING_SCREEN,
          state: { data },
        });
      },
      (e) => {
        errorNotification(
          getErrorMessage(e, LOCALIZATION.UN_SUCCESSFULLY_LOGIN_MESSAGE)
        );
      }
    );
  };

  return (
    <AuthenticationBase loading={loading} isLoginScreen={true}>
      <h2 className="sub-head">{LOCALIZATION.LOGIN}</h2>

      <Form form={form} ref={formRef} onFinish={onSubmit} layout="vertical">
        <div className="form-group">
          <Email
            className="form-control"
            name="email"
            required={true}
            placeholder={LOCALIZATION.EMAIL_ADDRESS}
          />
        </div>

        <div className="form-group">
          <Password
            className="form-control"
            name="password"
            required={true}
            minLength={1}
            placeholder={LOCALIZATION.PASSWORD}
          />
        </div>

        <Link to={APP_URL.FORGOT_PASSWORD}>
          {LOCALIZATION.FORGOT_PASSWORD}?
        </Link>

        <Form.Item>
          <Button
            block
            size="medium"
            type="primary"
            htmlType="submit"
            className="mt-4 auth-action"
          >
            {LOCALIZATION.LOGIN}
          </Button>
        </Form.Item>
      </Form>
    </AuthenticationBase>
  );
}

export default Login;