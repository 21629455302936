/*
  Application Forgot Password
*/

import React from "react";

// Images
import FileNotFoundImage from '@Vytelle/sense/src/assets/images/file-not-found.png';

// Styles
import "./style.scss";

function Login() {

  return (
    <>
      <div className="file-not-found">
        <img
          className="file-not-found-image"
          src={FileNotFoundImage}
          alt="empty screen"
        />
        <p className="file-not-found-message">The file may have been moved, edited, or deleted.</p>
      </div>
    </>
  );
}

export default Login;
