/*
  Application Forgot Password
*/

import React from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Antd
import { Form, Button } from "antd";

// Components
import AuthenticationBase from "..";
import { Email } from "@Vytelle/authentication/src/components/Common/FormElements";

// Constants
import APP_URL from '@Vytelle/authentication/src/constants/ApplicationUrls';
import { REDUX_STATES } from '@Vytelle/authentication/src/constants/ReduxStates';
import { API_URLS_COMMON } from '@Vytelle/authentication/src/constants/ApiUrlCommon';

// Helper
import {
  getErrorMessage,
} from "@Vytelle/authentication/src/helpers/GeneralHelper";
import { errorNotification, successNotification } from "@Vytelle/authentication/src/helpers/Notification";

// Actions
import { postAction } from "@Vytelle/authentication/src/store/actions/CRUDAction";

// Localization
import LOCALIZATION from "@Vytelle/authentication/src/services/LocalizationService";

const { FORGOT_PASSWORD, LOADING } = REDUX_STATES;

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  // Refs
  const [form] = Form.useForm();
  let formRef = React.createRef();

  // Redux States
  const {
    [FORGOT_PASSWORD + LOADING]: loading = false
  } = useSelector((state) => state?.Crud);

  const onSubmit = (data) => {

    dispatch(
      postAction(
        API_URLS_COMMON.FORGOT_PASSWORD,
        data,
        {},
        FORGOT_PASSWORD
      )
    ).then(
      () => { // Success Flow
        successNotification(LOCALIZATION.PASSWORD_RESET_LINK_SENT);
        history.push(APP_URL.FORGOT_PASSWORD_SUCCESS);
      },
      (e) => {  // Error
        errorNotification(
          getErrorMessage(
            e, 
            LOCALIZATION.EMAIL_NOT_VALID
          )
        );
      }
    );
  };

  return (
    <AuthenticationBase 
      loading={ loading }
    >
      <h2 className="sub-head">{ LOCALIZATION.FORGOT_PASSWORD }</h2>
      <p>{ LOCALIZATION.RESET_PASSWROD_PASSWORD_MESSAGE }</p>

      <Form 
        form={form} 
        ref={formRef} 
        onFinish={onSubmit} 
        layout="vertical"
      >
        <div className="form-group">
          <Email
            className="form-control"
            name="email"
            required={true}
            placeholder={LOCALIZATION.EMAIL_ADDRESS}
          />
        </div>
        <Form.Item>
          <Button
            block
            size="medium"
            type="primary"
            htmlType="submit"
            className="mt-4 auth-action"
          >
            { LOCALIZATION.RESET_YOUR_PASSWORD }
          </Button>
        </Form.Item>
      </Form>
      

    </AuthenticationBase>
  );
}

export default Login;
