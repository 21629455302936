const COMMON_API_BASE_URL = process.env.REACT_APP_COMMON_API_BASE_URL;
const DAQ_API_BASE_URL = process.env.REACT_APP_DAQ_API_BASE_URL;

const APP_AUTH = process.env.REACT_APP_AUTH;
const APP_ADMIN = process.env.REACT_APP_ADMIN;
const APP_SENSE = process.env.REACT_APP_SENSE;
const APP_NETWORK = process.env.REACT_APP_NETWORK;
const APP_ADVANCE = process.env.REACT_APP_ADVANCE;


const APP_ADMIN_HOT_JAR_ID = process.env.REACT_APP_HOT_JAR_SITE_ID;
const APP_ADMIN_HOT_VERSION = process.env.REACT_APP_HOT_JAR_VERSION;

export { 
    COMMON_API_BASE_URL, 
    DAQ_API_BASE_URL,

    APP_AUTH,
    APP_ADMIN,
    APP_SENSE,
    APP_NETWORK,
    APP_ADVANCE,
    
    APP_ADMIN_HOT_JAR_ID,
    APP_ADMIN_HOT_VERSION,
};
