/*
  Actual application starts from this file
*/

import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'; //https://css-tricks.com/the-hooks-of-react-router/

// Redux
import { useSelector } from 'react-redux';
import { userPermissions } from '@Vytelle/authentication/src/store/actions/AuthAction';

// Antd
import { notification } from 'antd';

// Constants
import APP_URL from '@Vytelle/authentication/src/constants/ApplicationUrls';
import { NOTIFICATION_DURATION } from '@Vytelle/authentication/src/constants/General';

// Components
import Loading from '@Vytelle/authentication/src/components/Loading';

// Auth Components
import Login from '@Vytelle/authentication/src/components/Authentication/Login';
import ResetPassword from '@Vytelle/authentication/src/components/Authentication/ResetPassword';
import ForgotPassword from '@Vytelle/authentication/src/components/Authentication/ForgotPassword';
import LandingScreen from '@Vytelle/authentication/src/components/BaseLayout/LandingScreen';
import HelpOnTheWay from '@Vytelle/authentication/src/components/Authentication/HelpOnTheWay';
import FileNotFound from '@Vytelle/authentication/src/components/FileNotFound';

// Localization
import LOCALIZATION from '@Vytelle/authentication/src/services/LocalizationService';

// Styling
import './assets/sass/style.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import ReactStorageService from '@Vytelle/authentication/src/services/StorageService';

//hotjar
import Hotjar from "@hotjar/browser";

import {
  APP_ADMIN_HOT_JAR_ID,
  APP_ADMIN_HOT_VERSION,
} from "config/environment";

// Package.json
const packageJson = require('../package.json');

// Notification Configuration for whole application
notification.config({
  placement: 'topRight',
  duration: NOTIFICATION_DURATION,
  rtl: false
});

function App() {
  const dispatch = useDispatch();
  const { permissionLoading, permissionError } = useSelector((state) => state?.Auth);
  const getToken = ReactStorageService.instance.getAccessToken();


  useEffect(() => {
    if (!!APP_ADMIN_HOT_JAR_ID) {
      Hotjar.init(APP_ADMIN_HOT_JAR_ID, APP_ADMIN_HOT_VERSION);

      // Initializing with `debug` option:
      Hotjar.init(APP_ADMIN_HOT_JAR_ID, APP_ADMIN_HOT_VERSION, {
        debug: true,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(userPermissions())
  }, [getToken]);

  if (permissionLoading) {
    <Loading />
  }
  if (!permissionLoading && permissionError) {
    return <>Roles are not assigned to the user</>
  }
  return (
    <div className={`App`}>
      <BrowserRouter basename={packageJson?.homepage || ''}>
        <Switch>
          {/* 
              Open Routes Here 
            */}
          {/* Login */}
          <Route
            path={APP_URL.LOGIN}
            name={LOCALIZATION.LOGIN}
            component={Login}
          />

          {/* Reset Password */}
          <Route
            path={APP_URL.RESET_PASSWORD}
            name={LOCALIZATION.RESET_YOUR_PASSWORD}
            component={ResetPassword}
          />

          {/* Forget Your Password */}
          <Route
            path={APP_URL.FORGOT_PASSWORD}
            name={LOCALIZATION.FORGOT_PASSWORD}
            component={ForgotPassword}
          />

          {/* Landing Screen */}
          <Route
            path={APP_URL.APP_LANDING_SCREEN}
            name={ LOCALIZATION.LANDING_SCREEN }
            component={LandingScreen}
          />

          {/* Help */}
          <Route
            path={APP_URL.FORGOT_PASSWORD_SUCCESS}
            name={ LOCALIZATION.HELP }
            component={HelpOnTheWay}
          />

          {/* File not found */}
          <Route
            path={APP_URL.FILE_NOT_FOUND}
            component={ FileNotFound }
          />

          <Redirect
            from="/"
            to={"/login"}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;