/*
    General Helper Class
*/

// example: https://bithacker.dev/javascript-object-multi-property-sort

import StorageService from "@Vytelle/authentication/src/services/StorageService";

import LOCALIZATION from "@Vytelle/authentication/src/services/LocalizationService";

// Config
import {
  COMMON_API_BASE_URL,
  DAQ_API_BASE_URL,
} from "@Vytelle/authentication/src/config/environment";

// Constants
import { API_URLS_COMMON } from "@Vytelle/authentication/src/constants/ApiUrlCommon";

export const getBaseUrl = (url) => {
  let flag = false;
  Object.values(API_URLS_COMMON).forEach((el) => {
    if (!flag && url?.includes(el) && url.slice(0, 2) === el.slice(0, 2)) {
      flag = true;
    }
  });

  return flag ? COMMON_API_BASE_URL : DAQ_API_BASE_URL;
};

export const getName = () => {
  const { firstName, fullName } = StorageService.instance.getUserInfo() || {};

  return firstName || fullName;
};

export const getRole = (appId, roleId = false) => {
  const { applicationRole } = StorageService.instance.getUserInfo();
  const appInfo = applicationRole.find((app) => app.application.id === appId);
  return roleId ? appInfo?.role?.id : appInfo?.role?.name;
};

export const parseJwt = (token) => {
  var base64Url = token?.split(".")[1];
  var base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const disabledFutureDates = (current) => {
  return current && current.valueOf() > Date.now();
};

// Sort an array
export function sortArray(array = [], sortBy) {
  return (
    array &&
    array?.sort(function (a, b) {
      let i = 0,
        result = 0;
      while (i < sortBy.length && result === 0) {
        result =
          sortBy[i].direction *
          (a[sortBy[i]?.prop]?.toString() < b[sortBy[i]?.prop]?.toString()
            ? -1
            : a[sortBy[i]?.prop]?.toString() > b[sortBy[i]?.prop]?.toString()
            ? 1
            : 0);
        i++;
      }
      return result;
    })
  );
}

export function truncateString(str, n) {
  if (str?.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
}

export function camalize(str) {
  const words = str.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1).toLowerCase();
  }

  return words.join(" ");
}

// Get Int value of given value
export function getIntVal(value) {
  return parseInt(value || 0);
}

// Add Thousand Separator in value
export function thousandSeprator(x) {
  return x.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// check for empty obj
export function checkObjIsEmpty(obj) {
  return !Object.keys(obj).length;
}

// get error message
export function getErrorMessage(e, defaultMessage = LOCALIZATION.ACTON_FAILED) {
  const errors = e?.response?.data?.errors;
  const errorMessage = [defaultMessage];
  if (
    !!errors &&
    ((Array.isArray(errors) && !!errors?.length) || !Array.isArray(errors))
  ) {
    return errors;
  } else {
    return errorMessage;
  }
}

export function getPlaceholder(placeholder) {
  return `Select ${placeholder?.toLowerCase()}`;
}

export function getRoleByApplication(
  applicationRoles = [],
  currentApplication = 0
) {
  const roleObject = applicationRoles?.filter(
    (object) => object?.application?.id === currentApplication
  );

  return roleObject?.length !== 0 ? roleObject[0]?.role?.name : null;
}

/**
 * A functions that takes params as a parameter and returns an filter object
 */
export const persistFilters = (params) => {
  const { sortKey, orderBy, pageNumber, pageSize, searchText, ...filterState } =
    params;
  const formatFilters = {
    filters: { ...filterState },
    sort: {
      key: sortKey,
      order: orderBy,
    },
    pagination: {
      pageNumber,
      pageSize,
    },
    searchText,
  };
  return formatFilters;
};