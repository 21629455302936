export const REDUX_STATES = {
  DEFAULT_KEY: "listing" + new Date().getTime(),
  DEFAULT_DROPDOWN_KEY: "dropdown" + new Date().getTime(),
  DEFAULT_POST_KEY: "post" + new Date().getTime(),
  DEFAULT_PUT_KEY: "put" + new Date().getTime(),
  DEFAULT_PATCH_KEY: "patch" + new Date().getTime(),
  DEFAULT_DELETE_KEY: "delete" + new Date().getTime(),
  DEFAULT_SELECTED_KEY: "selected" + new Date().getTime(),
  LOADING: "_loading",
  ERROR: "_error",
  RESPONSE: "_response",
  PARAMS: "_params",
  FORGOT_PASSWORD: "forgotPassword",
  VALIDATE_TOKEN: "validToken",
  RESET_PASSWORD: "resetToken"
};
