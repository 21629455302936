/*
  Application Reset Password
*/
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Antd
import { Form, Button } from "antd";

// Components
import AuthenticationBase from "..";
import { Password } from "@Vytelle/authentication/src/components/Common/FormElements";

// Constants
import APP_URL from "@Vytelle/authentication/src/constants/ApplicationUrls";
import { API_URLS_COMMON } from "@Vytelle/authentication/src/constants/ApiUrlCommon";
import { REDUX_STATES } from "@Vytelle/authentication/src/constants/ReduxStates";

// Helper
import { getErrorMessage } from "@Vytelle/authentication/src/helpers/GeneralHelper";
import {
  errorNotification,
  successNotification,
} from "@Vytelle/authentication/src/helpers/Notification";

// Actions
import { postAction } from "@Vytelle/authentication/src/store/actions/CRUDAction";

// Localization
import LOCALIZATION from "@Vytelle/authentication/src/services/LocalizationService";

const { VALIDATE_TOKEN, RESET_PASSWORD, LOADING } = REDUX_STATES;

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  // Refs
  const [form] = Form.useForm();
  let formRef = React.createRef();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const code = searchParams.get("Code");
  const isNew = searchParams.get("IsNew");

  // Redux States
  const loading = useSelector((state) => state?.Auth?.loading);
  const {
    [VALIDATE_TOKEN + LOADING]: validateTokenloading = false,
    [RESET_PASSWORD + LOADING]: resetPasswordloading = false,
  } = useSelector((state) => state?.Crud);

  useEffect(() => {
    validateCode();
  }, []);

  const validateCode = () => {
    dispatch(
      postAction(
        API_URLS_COMMON.VALIDATE_RESET_PASSWORD_LINK,
        code,
        {},
        VALIDATE_TOKEN
      )
    ).then(
      () => {
        successNotification(LOCALIZATION.RESET_PASSWORD_TOKEN_VALID);
      },
      (e) => {
        errorNotification(
          getErrorMessage(e, LOCALIZATION.RESET_PASSWORD_TOKEN_EXPIRED)
        );
        history.push(APP_URL.LOGIN);
      }
    );
  };

  const onSubmit = (data) => {
    const { password, confirmPassword } = data || {};

    if (password === confirmPassword) {
      dispatch(
        postAction(
          API_URLS_COMMON.RESET_PASSWORD,
          {
            token,
            code,
            isNew: isNew === "true",
            ...data,
          },
          {},
          RESET_PASSWORD
        )
      ).then(
        () => {
          successNotification(LOCALIZATION.PASSWORD_UPDATED_SUCCESSFULLY);
          history.push(APP_URL.LOGIN);
        },
        (e) => {
          alert("Error");
        }
      );
    } else {
      errorNotification(LOCALIZATION.NEW_CONFIRM_PASSWORD_NOT_SAME);
    }
  };

  return (
    <AuthenticationBase
      loading={loading || validateTokenloading || resetPasswordloading}
    >
      <h2 className="sub-head">{LOCALIZATION.RESET_YOUR_PASSWORD}</h2>

      <Form form={form} ref={formRef} onFinish={onSubmit} layout="vertical">
        <div className="form-group">
          <Password
            className="form-control"
            name="password"
            required={true}
            minLength={1}
            placeholder={LOCALIZATION.NEW_PASSWORD}
            validator={[
              { min: 8, message: LOCALIZATION.PASSWORD_MUST_HAVE_8_CHARACTERS },
            ]}
          />
        </div>

        <div className="form-group">
          <Password
            className="form-control"
            name="confirmPassword"
            required={true}
            minLength={1}
            placeholder={LOCALIZATION.CONFIRM_PASSWORD}
          />
        </div>

        <Form.Item>
          <Button
            block
            size="medium"
            type="primary"
            htmlType="submit"
            className="mt-4 auth-action"
          >
            {LOCALIZATION.CONFIRM}
          </Button>
        </Form.Item>
      </Form>
    </AuthenticationBase>
  );
}

export default Login;
