/*
  Boot component for application.
*/

import React from 'react';

// Redux
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

// Antd
import { ConfigProvider } from 'antd';

// Configuration
import configureStore from "@Vytelle/authentication/src/store/configureStore";

// Components
import App from 'App';

// Storage Devices
import StorageService from '@Vytelle/authentication/src/services/StorageService';
import StorageServiceSense from "@Vytelle/authentication/src/services/StorageService"

// Stores & History
const store = configureStore();
const history = createBrowserHistory();

function BootSetup() {
  ConfigProvider.config({
    theme: {
      token: {
        fontSize: 16,
        fontFamily: 'DIN2014',
        colorPrimary: '#ED4C2F',
        borderRadius: 4,
        controlHeightLG: 48
      },
      hashed: false
    },
  });

  // Save Platform Web
  StorageService.instance = new StorageService(localStorage);
  StorageServiceSense.instance = new StorageServiceSense(localStorage);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
          fontFamily: 'DIN2014',
          colorPrimary: '#ED4C2F',
          borderRadius: 4,
          controlHeightLG: 48
        },
        hashed: false
      }}
    >
      <Provider
        store={store}
        history={history}
      >
        <App />
      </Provider>
    </ConfigProvider>
  );

}

export default BootSetup;