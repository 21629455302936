export const LANGUAGE = {
  EN: 1,
};

export const API_TIMEOUT = 90000;

export const NOTIFICATION_DURATION = 4;

export const APPLICATION_ID = 4;

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "VYTELLE_GENERAL_ACCESS_TOKEN",
  USER_INFO: "VYTELLE_GENERAL_USER_INFO",
  USER_ROLES: "VYTELLE_GENERAL_USER_ROLES",
  PERMISSIONS: "VYTELLE_APP_PERMISSIONS",
  USER_PREFERENCE: "VYTELLE_PREFERENCE",
};

// Available Vytelle Apps
export const VYTELLE_APPS = {
  SENSE: 1,
  ADVANCE: 2,
  NETWORK: 3,
  ADMIN: 4,
};

export const LISTING_DATA = {
  FIRST_PAGE: 0,
  PAGE_SIZE: 40,
  PAGE_SIZE_MAX_SIZE: 9999,
  PAGE_SIZE_LIVE_STATUS: 100,
};


export const SEARCH_TYPING_INTERVAL = 600;
export const JWT_TOKEN = "jwtToken";
