import { LOCAL_STORAGE_KEYS } from "@Vytelle/authentication/src/constants/General";

export default class ReactStorageService {
  // Constuctor
  constructor(storage) {
    this.localStorage = storage;
  }

  // Set Access Token
  setAccessToken(accessToken) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
      accessToken || null
    );
  }

  // Get Access Token
  getAccessToken() {
    try {
      const token = this.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      return token;
    } catch (error) {
      return "";
    }
  }

  // Set User Info
  setUserInfo(info) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER_INFO,
      JSON.stringify(info || {})
    );
  }

  // Get User Info
  getUserInfo() {
    try {
      const info = this.localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO);
      return JSON.parse(info);
    } catch (error) {
      return "";
    }
  }

  // Set Roles
  setRoles(roles) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER_ROLES,
      JSON.stringify(roles || [])
    );
  }

  // Get Roles
  getRoles() {
    try {
      const roles = this.localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ROLES);
      return JSON.parse(roles);
    } catch (error) {
      return "";
    }
  }

  deleteLoginData() {
    this.localStorage.clear();
  }

  // Permissions
  setAppPermissions(permission) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEYS.PERMISSIONS,
      JSON.stringify(permission || {})
    );
  }

  getAppPermissions() {
    try {
      const permissions = this.localStorage.getItem(
        LOCAL_STORAGE_KEYS.PERMISSIONS
      );
      return JSON.parse(permissions);
    } catch (error) {
      return {};
    }
  }

  // Permissions
  setAppPreference(permission) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER_PREFERENCE,
      JSON.stringify(permission || {})
    );
  }

  getAppPreference() {
    try {
      const preference = this.localStorage.getItem(
        LOCAL_STORAGE_KEYS.USER_PREFERENCE
      );
      return JSON.parse(preference);
    } catch (error) {
      return {};
    }
  }
}
