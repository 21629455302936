// Service file that handle api client and there methods
import axios from "axios";
// storage instance
import StorageService from "@Vytelle/authentication/src/services/StorageService";
// constants
import {
  API_TIMEOUT,
  APPLICATION_ID,
} from "@Vytelle/authentication/src/constants/General";

// Helper
import { getBaseUrl } from "@Vytelle/authentication/src/helpers/GeneralHelper";

export const apiClient = () => {
  const token = StorageService?.instance?.getAccessToken();

  // Dummy Condition, will remove with actual api
  let defaultOptions = !!token
    ? {
        headers: {
          Authorization: token ? `Bearer ${token || null}` : "",
          "Content-Type": "application/json",
          "X-ApplicationIDHeader": APPLICATION_ID,
        },
      }
    : {
        headers: {
          "Content-Type": "application/json",
          "X-ApplicationIDHeader": APPLICATION_ID,
        },
      };

  // Request timeout
  defaultOptions = {
    ...defaultOptions,
    timeout: API_TIMEOUT,
  };

  return {
    get: (url, options = {}) =>
      axios.get(`${getBaseUrl(url)}${url}`, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) =>
      axios.post(`${getBaseUrl(url)}${url}`, data, {
        ...defaultOptions,
        ...options,
      }),
    put: (url, data, options = {}) =>
      axios.put(`${getBaseUrl(url)}${url}`, data, {
        ...defaultOptions,
        ...options,
      }),
    patch: (url, data, options = {}) =>
      axios.patch(`${getBaseUrl(url)}${url}`, data, {
        ...defaultOptions,
        ...options,
      }),
    delete: (url, data, options = {}) =>
      axios.delete(`${getBaseUrl(url)}${url}`, {
        ...defaultOptions,
        ...options,
        data,
      }),
  };
};
