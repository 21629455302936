import { USER_ACTIONS } from "@Vytelle/authentication/src/constants/ActionKeys";

let initialState = {
  loading: false,
  error: false,
};

const Auth = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case USER_ACTIONS.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case USER_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case USER_ACTIONS.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    // Logout
    case USER_ACTIONS.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case USER_ACTIONS.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case USER_ACTIONS.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    // Permissions
    case USER_ACTIONS.PERMISSIONS_REQUEST:
      return {
        ...state,
        permissionLoading: true,
        permissionError: false,
      };

    case USER_ACTIONS.PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionLoading: false,
        permissionError: false,
      };

    case USER_ACTIONS.PERMISSIONS_FAILURE:
      return {
        ...state,
        permissionLoading: false,
        permissionError: true,
      };

    default:
      return state;
  }
};

export default Auth;
