// Service
import service from "@Vytelle/authentication/src/services/Api/Service";

// Constants
import { CRUD_ACTION } from "@Vytelle/authentication/src/constants/ActionKeys";

// General
import { handleAction } from ".";
import { REDUX_STATES } from "@Vytelle/authentication/src/constants/ReduxStates";
import { persistFilters } from "@Vytelle/authentication/src/helpers/GeneralHelper";

/**************
  Get Action 
*************/
function getAction(url, data, key) {
  return async (dispatch) => {
    try {
      dispatch(
        handleAction(CRUD_ACTION.GET_REQUEST, {
          key,
          ...(data.params && { params: persistFilters(data.params) }), // if params exist then save them in store
        })
      );
      if (data?.params) {
        // Persists params when data contains them
        dispatch(
          handleAction(
            `${key}${REDUX_STATES.PARAMS}`,
            persistFilters(data.params)
          )
        );
      }

      // API Calling
      const response = await service.getService(url, data);

      if (response) {
        dispatch(handleAction(CRUD_ACTION.GET_SUCCESS, { key, response }));
      } else {
        dispatch(handleAction(CRUD_ACTION.GET_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(handleAction(CRUD_ACTION.GET_FAILURE, { key, error }));
      throw error;
    }
  };
}

/**************
  Post Action 
*************/
function postAction(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(handleAction(CRUD_ACTION.POST_REQUEST, { key }));

      // API Calling
      const response = await service.postService(url, data, opt);

      if (response) {
        dispatch(handleAction(CRUD_ACTION.POST_SUCCESS, { key, response }));
      } else {
        dispatch(handleAction(CRUD_ACTION.POST_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(handleAction(CRUD_ACTION.POST_FAILURE, { key, error }));
      throw error;
    }
  };
}

/**************
  Put Action 
*************/
function putAction(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(handleAction(CRUD_ACTION.PUT_REQUEST, { key }));

      // API Calling
      const response = await service.putService(url, data, opt);
      dispatch(handleAction(CRUD_ACTION.PUT_SUCCESS, { key, response }));
      return response;
    } catch (error) {
      dispatch(handleAction(CRUD_ACTION.PUT_FAILURE, { key, error }));
      throw error;
    }
  };
}

/**************
  Patch Action 
*************/
function patchAction(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(handleAction(CRUD_ACTION.PATCH_REQUEST, { key }));

      // API Calling
      const response = await service.patchService(url, data, opt);
      if (response) {
        dispatch(handleAction(CRUD_ACTION.PATCH_SUCCESS, { key, response }));
      } else {
        dispatch(handleAction(CRUD_ACTION.PATCH_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(handleAction(CRUD_ACTION.PATCH_FAILURE, { key, error }));
      throw error;
    }
  };
}

/**************
  Delete Action 
*************/
function deleteAction(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(handleAction(CRUD_ACTION.DELETE_REQUEST, { key }));

      // API Calling
      const response = await service.deleteService(url, data, opt);
      dispatch(handleAction(CRUD_ACTION.DELETE_SUCCESS, { key, response }));
      return response;
    } catch (error) {
      dispatch(handleAction(CRUD_ACTION.DELETE_FAILURE, { key, error }));
      throw error;
    }
  };
}

/*******************
  Update a Key Data
*******************/
function updateKeyData(data, key) {
  return async (dispatch) => {
    dispatch(handleAction(CRUD_ACTION.UPDATE_KEY_DATA, { key, data }));
  };
}

function updateKeyFlag(flag, key) {
  return async (dispatch) => {
    dispatch(handleAction(CRUD_ACTION.UPDATE_KEY_FLAG, { key, flag }));
  };
}

/*******************
  Reset Key Data
*******************/
function resetKeyData() {
  return async (dispatch) => {
    dispatch(handleAction(CRUD_ACTION.RESET_KEY_DATA));
  };
}

export {
  getAction,
  postAction,
  putAction,
  patchAction,
  deleteAction,
  updateKeyData,
  resetKeyData,
  updateKeyFlag
};
