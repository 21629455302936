//constants
import { JWT_TOKEN } from "@Vytelle/authentication/src/constants/General";

//cookies
import { useCookies } from "react-cookie";

// import 

export const useCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const setCookieData = (appUrl, token, domain) => {
    const t = getToken();
    if (!t) {
      setCookie(JWT_TOKEN, token, {
        path: "/",
        domain: domain,
      });

    }
  };

  const getCookiesData = () => {
    return cookies;
  };

  const clearCookies = (key = null) => {
    // removeCookie(JWT_TOKEN);
    removeCookieByKey(JWT_TOKEN);

  };

  const getToken = () => {
    const token = cookies?.[JWT_TOKEN];
    return token;
  };

  const setCookieByKey = (key, value, domain = "") => {
    setCookie(key, value, {
      path: "/",
      domain: domain,
    });
  };

  const removeCookieByKey = (domain, key = JWT_TOKEN) => {
    removeCookie(key, {
      path: "/",
      domain: domain,
    });
  };

  const getCookieByKey = (key) => {
    const value = cookies?.[key];
    return value;
  };

  return {
    getToken,
    setCookieData,
    getCookiesData,
    clearCookies,
    setCookieByKey,
    removeCookieByKey,
    getCookieByKey,
  };
};
