/* 
  App Landing Screen
*/

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";

// Antd
import { Layout, Modal, Dropdown, Row, Col, Space } from "antd";

// Components
import Platform from "./Platform";

// Constants
import APP_URL from "@Vytelle/authentication/src/constants/ApplicationUrls";

// Actions
import { logout } from "@Vytelle/authentication/src/store/actions/AuthAction";

// Config
import {
  APP_ADMIN,
  APP_SENSE,
  APP_NETWORK,
  APP_ADVANCE,
} from "@Vytelle/authentication/src/config/environment";

// Component
import NoPlatform from "./NoPlatform";

import { VYTELLE_APPS } from "@Vytelle/authentication/src/constants/General";

// Localization
import LOCALIZATION from "@Vytelle/authentication/src/services/LocalizationService";

// Images
import vytelleLogo from "../../../assets/images/vytelle-logo.svg";
import adminLogo from "../../../assets/images/admin-app-logo.svg";
import advanceLogo from "../../../assets/images/advance-logo.svg";
import insightLogo from "../../../assets/images/insight-logo.svg";
import senseLogo from "../../../assets/images/sense-logo.svg";

// Icons
import { ArrowIcon, CrossMarkIcon } from "@Vytelle/authentication/src/assets/icons";

// Style
import "./style.scss";
import { useCookie } from "@Vytelle/authentication/src/hooks/useCookie";

const { Header, Content } = Layout;

const HIDE_ADMIN = [660, 1040];

function LandingPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setCookieData } = useCookie();

  const onLogoutConfirm = () => {
    Modal.confirm({
      width: 525,
      title: LOCALIZATION.CONFIRM,
      icon: null,
      closable: true,
      closeIcon: <CrossMarkIcon />,
      content: LOCALIZATION.ARE_YOU_SURE_LOGOUT,
      okButtonProps: {
        type: "primary",
        size: "large",
      },
      okText: LOCALIZATION.YES,
      cancelText: LOCALIZATION.NO,
      cancelButtonProps: {
        size: "large",
      },
      onOk: onLogout,
    });
  };

  const onLogout = () => {
    dispatch(logout())?.then(() => {
      // setCookieByKey("logout", true);
      history.push(APP_URL.LOGIN);
    });
  };

  // On Menu
  const menu = [
    {
      key: "1",
      label: LOCALIZATION.LOGOUT,
      onClick: onLogoutConfirm,
    },
  ];

  const { state } = useLocation() || {};
  const { data } = state || {};

  const { id, firstName, fullName, jwtToken, userApplicationRoles } = data || {};

  useEffect(() => {
    window.addEventListener("popstate", (event) => {
      if (!!event.state || event.state === null) {
        window?.history?.forward();
        return;
      }
    });

    if (userApplicationRoles?.length === 1) {
      switch (userApplicationRoles?.[0].application.id) {
        case VYTELLE_APPS.SENSE:
          return onPlatformSelection(APP_SENSE);
        case VYTELLE_APPS.ADVANCE:
          return onPlatformSelection(APP_ADVANCE, 1);
        case VYTELLE_APPS.NETWORK:
          return onPlatformSelection(APP_NETWORK, 2);
        case VYTELLE_APPS.ADMIN:
          return onPlatformSelection(APP_ADMIN);
        default:
          return null;
      }
    }
    return () => {
      window.removeEventListener("popstate", () => {});
    };
  }, []);

  useEffect(() => {
    !data && onLogout();
  }, [data]);

  const onPlatformSelection = (url, q = null) => {
    let appUrl =
      url?.charAt(url.length - 1) === "/"
        ? url.substring(0, url.length - 1)
        : url;

    let domain = "";
    const hostName = window.location.hostname || "";

    if (hostName !== "localhost") {
      if(hostName === "vytelle.io") {
        domain = hostName;
      } else {
        if (!!hostName?.length) {
          domain = hostName.substring(hostName?.indexOf("."));
          if (domain?.includes(":")) {
            domain = domain.split(":")[0];
          }
        }  
      }
    }

    setCookieData(appUrl, jwtToken, domain);

    let urlToRedirect = appUrl + APP_URL.LOGIN;

    if (q != null) {
      urlToRedirect = urlToRedirect + "?p=" + q;
    }

    window.location.replace(urlToRedirect);
  };

  const isPlatformExist = (id) => {
    return (
      userApplicationRoles?.findIndex((el) => el.application.id === id) !== -1
    );
  };

  return userApplicationRoles?.length === 1 ? (
    <Space
      direction="vertical"
      align="center"
      style={{ width: "100%", justifyContent: "center" }}
    >
      <span>Please Wait...</span>
    </Space>
  ) : (
    <>
      <Header
        className="app-header"
        style={{ position: "sticky", top: 0, zIndex: 1, width: "100%" }}
      >
        <Row className="align-center">
          <Col span={4} className="d-flex align-center">
            <img src={vytelleLogo} alt="logo" />
          </Col>
          <Col span={20} className="userProfile d-flex justify-end">
            <Dropdown menu={{ items: menu }}>
              <div
                role="button"
                className="userProfile-btn"
                onClick={(e) => e.preventDefault()}
              >
                <div className="user-title-circle">
                  <span>{(firstName || fullName)?.[0] || "V"}</span>
                </div>
                <div className="user-title-full">
                  <span>{firstName || fullName}</span>
                </div>
                <ArrowIcon className="arrow" />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      {!!userApplicationRoles?.length ? (
        <Layout className="platform-selection">
          <Content className="platform-layout">
            <Row gutter={[12, 12]}>
              <Col span={24} className="user-info">
                <h3>Hi {firstName || fullName},</h3>
                <p>{LOCALIZATION.PLATFORM_SELECTION_MESSAGE}</p>
              </Col>
              <Col span={24} className="platforms">
                <Row gutter={[24, 24]}>
                  {isPlatformExist(VYTELLE_APPS.NETWORK) && (
                    <Platform
                      logo={insightLogo}
                      text={LOCALIZATION.PLATFORM_INSIGHT_MESSAGE}
                      onClick={() => onPlatformSelection(APP_NETWORK, 2)}
                    />
                  )}

                  {isPlatformExist(VYTELLE_APPS.SENSE) && (
                    <Platform
                      logo={senseLogo}
                      text={LOCALIZATION.PLATFORM_SENSE_MESSAGE}
                      onClick={() => onPlatformSelection(APP_SENSE)}
                    />
                  )}

                  {isPlatformExist(VYTELLE_APPS.ADVANCE) && (
                    <Platform
                      logo={advanceLogo}
                      text={LOCALIZATION.PLATFORM_ADVANCE_MESSAGE}
                      onClick={() => onPlatformSelection(APP_ADVANCE, 1)}
                    />
                  )}

                  {isPlatformExist(VYTELLE_APPS.ADMIN) && !HIDE_ADMIN.includes(id) && (
                    <Platform
                      logo={adminLogo}
                      text={LOCALIZATION.PLATFORM_ADMIN_MESSAGE}
                      onClick={() => onPlatformSelection(APP_ADMIN)}
                    />
                  )}
                </Row>
              </Col>
            </Row>
          </Content>
        </Layout>
      ) : (
        <NoPlatform />
      )}
    </>
  );
}

export default LandingPage;
