/*
  This file contain entire application's URL
*/

// URLS
import { GENERAL_URLS } from "@Vytelle/authentication/src/constants/MenuURLS/GeneralManagement";

const URL = {
  LOGIN: "/login", // Login
  RESET_PASSWORD: "/reset-password", // Reset Password
  FORGOT_PASSWORD: "/forgot-password", // Forgot Password
  FORGOT_PASSWORD_SUCCESS: "/forgot-password-success",
  FILE_NOT_FOUND: "/file-not-found",

  ERROR: {
    PAGE404: "/page404",
    PAGE500: "/page500",
  },

  ...GENERAL_URLS
};

export default URL;
